import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import DonutChart from './Dashboard/DonutChart';
import { UseAPI } from '../../../services/UseAPI';
import Tryout from '../Tryout/Tryout';

const Home = () => {
	const [data,setData] = useState([]);
	useEffect(()=>{
		UseAPI('participant_tryouts/user',{},'GET').then((resp)=>{
			setData(resp);
			console.log("resp",resp);
		})
	},[])
	return(
		<>
			<Tryout targetPage="dashboard" />
		</>
	)
}
export default Home;