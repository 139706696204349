import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import DonutChart from '../Dashboard/Dashboard/DonutChart';
import { UseAPI } from '../../../services/UseAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
const Tryout = () => {
	const [data,setData] = useState([]);
	useEffect(()=>{
		UseAPI('participant_tryouts/user',{},'GET').then((resp)=>{
			setData(resp);
			console.log("resp",resp);
		})
	},[])
	return(
		<>
			<div className="row mb-5">
				{
					data.map((item,key) => (
						<div className="col-xl-4 col-lg-4" key={key}>
							<div className="row">
								<div className="col-xl-12">
								<div className="card">
									<div className="card-body my-3">	
										<div className="row">
											<div className="col-xl-12 col-xxl-12 col-md-12">
												<div className="row">
													<div className="d-flex col-xl-12 col-xxl-12  col-md-12 col-12 mb-4">
														<svg className="me-3" width="14" height="54" viewBox="0 0 14 54" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect x="-6.10352e-05" width="14" height="54" rx="7" fill="#AC39D4"/>
														</svg>
														<div>
															<p className="fs-14 mb-2">{`${item.type_name} : ${item.tryout_name}`}</p>
															<span className="fs-16 font-w600 text-light"><span className="text-black me-2 font-w700">{item.start_date} s.d {item.end_date}</span></span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-xl-12 col-xxl-12 col-md-12">
												<div className="row">
													<div className="col-6 mb-4">
														<div className="bg-gradient3 rounded text-center p-3">
															<div className="d-inline-block position-relative donut-chart-sale mb-3 text-white">
																Jumlah Soal
															</div>
															<span className="fs-14 text-white d-block">{item.jml_soal}</span>
														</div>
													</div>
													<div className="col-6 mb-4">
														<div className="bg-gradient3 rounded text-center p-3">
															<div className="d-inline-block position-relative donut-chart-sale mb-3 text-white">
																Durasi
															</div>
															<span className="fs-14 text-white d-block">{item.exam_duration_minutes} mnt</span>
														</div>
													</div>
												</div>
											</div>
										</div>							
									</div>
									<div className="card-footer border-0 pt-0">
										<Link to={"/tryout/"+item.id} className="btn btn-primary d-block btn-lg text-uppercase">{item.is_expired ? "Tidak Tersedia" : "Lihat"}</Link>												
									</div>
								</div>


								</div>
							</div>
						</div>				
					))
				}
			</div>	
		</>
	)
}
export default Tryout;