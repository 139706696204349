export function UseAPI(type, userData, method) {
    let BaseURL = 'https://apps.unismuh.ac.id/examku/';
    return new Promise((resolve, reject) =>{    
        switch (method) {            
            case 'POST':
                var formdata = new FormData();
                Object.entries(userData).map(([key, value]) =>
                    {
                        formdata.append(key,value)
                    }
                );                
                // formdata.append('idToken',(localStorage.getItem('userDetails')));
                
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow',
                    
                };
                if (type !== 'login' && type !== 'login-member') {
                    requestOptions.headers = {token:JSON.parse(localStorage['userDetails']).idToken,sessiontime:JSON.parse(localStorage['userDetails']).waktu??=''}
                }

                
                fetch(BaseURL+type, requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
                break;        
            case 'DELETE':

                var myHeaders = new Headers();
                myHeaders.append("Accept", "*/*");
                myHeaders.append("Accept-Language", "en,en-US;q=0.9");
                myHeaders.append("Access-Control-Request-Method", "DELETE");
                myHeaders.append("Connection", "keep-alive");
                myHeaders.append("Origin", "http://localhost:3000");
                myHeaders.append("Referer", "http://localhost:3000/");
                myHeaders.append("Sec-Fetch-Dest", "empty");
                myHeaders.append("Sec-Fetch-Mode", "cors");
                myHeaders.append("Sec-Fetch-Site", "cross-site");
                myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36");
                
                var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
                };

                if (type !== 'login') {
                    requestOptions.headers = {token:JSON.parse(localStorage['userDetails']).idToken,sessiontime:JSON.parse(localStorage['userDetails']).waktu??=''}
                }

                
                fetch(BaseURL+type, requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));

                break;        
            case 'GET':
                fetch(BaseURL+type, {
                    method: 'GET',
                    headers: {
                        token:JSON.parse(localStorage['userDetails']).idToken??='',
                        sessiontime:JSON.parse(localStorage['userDetails']).waktu??=''
                    }

                })
                .then((response) => response.json())
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });                        
                break;
        }
    });
}