import React, { useEffect, useState } from 'react';
import {Link, useParams } from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';

import profile from './../../../images/profile/pic2.jpg';
import wave from './../../../images/pattern/wave-dec.png';
import circle from './../../../images/pattern/circle.png';
import circle1 from './../../../images/pattern/circles1.png';
import Swal from 'sweetalert2';
import { UseAPI } from '../../../services/UseAPI';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faLock } from '@fortawesome/free-solid-svg-icons';


const TryoutDetail = ()=>{
	const { tryout_id } = useParams();
	const [data, setData] = useState({})
	const history = useHistory();
	// let navigate = useNavigate();
	const mulai = () => {
		console.log("id",tryout_id);
		let create_pass_code = UseAPI('pass_code/'+tryout_id,{},'GET').then((resp)=>{
			console.log(resp);
			if (resp.status == 'ok') {
				Swal.fire({
					title: 'Kode Konfirmasi',
					html: resp.pesan,
					input: 'text',
					inputAttributes: {
					  autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Konfirmasi',
					showLoaderOnConfirm: true,
					preConfirm: (confirmPassCode) => {
						return UseAPI('pass_code/confirm/'+tryout_id+'/'+confirmPassCode,{},'GET').then((resp_pass_code)=>{
							console.log(resp_pass_code);
							if (resp_pass_code.status == 'ok') {
								return resp_pass_code
							} else {
								throw new Error(resp_pass_code.pesan)
							}
						}).catch(error => {
							Swal.fire({
								icon:'error',
								title: `Error`,
								text: `${error}`
							})

						})
					},
					allowOutsideClick: () => !Swal.isLoading()
				}).then((result) => {
					if (result.isConfirmed) {
						console.log(result);
						let timerInterval
						Swal.fire({
							icon:'success',
							title: `Terkonfirmasi`,
							html:'<b></b>',
							// text: `${result.value.pesan}`,
							showConfirmButton:false,
							timer:3000,
							timerProgressBar: true,
							didOpen: () => {
								Swal.showLoading()
								const b = Swal.getHtmlContainer().querySelector('b')
								timerInterval = setInterval(() => {
								  b.textContent = parseInt((Swal.getTimerLeft()/1000)+1)
								}, 100)
							},
							  willClose: () => {
								clearInterval(timerInterval)
							}							
						}).then((result)=>{
							if (result.dismiss === Swal.DismissReason.timer) {
								history.push(`/ujian/${tryout_id}`)
								// navigate("/tryout/start")
							}
						})
					}
				})
			} else {
				Swal.fire({
					icon:'error',
					title: `Error`,
					text: `${resp.pesan}. `
				})
			}

	

		})		
		// fetch(`https://w`)	
		// .then(response => {
		//   if (!response.ok) {
		// 	throw new Error(response.statusText)
		//   }
		//   return response.json()
		// })		

	}

	useEffect(()=>{
		UseAPI(`participant_tryouts/user/${tryout_id}`,{},'GET').then((resp)=>{
			console.log("resp",resp);
			if (resp) {
				setData(resp);
			}
		})
	},[])
	return(
		<>
			<div className="row">	
				<div className="col-xl-12">
					<div className="card py-3">
						{data.length > 0 && (
						<>
							<div className="card-body">
								<div className="row align-items-center">
									
									<div className="col-xl-3 mb-3">
										<p className="mb-2 fs-16 font-w600">NIT</p>
										<h2 className="mb-0 fs-32 font-w800">{data[0] && data[0].phone_number}</h2>
									</div>
									<div className="col-xl-9 d-flex flex-wrap justify-content-between align-items-center">
										<div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
											{/* <i className="fas fa-phone-alt me-4 mt-2 scale5"></i> */}
											<div>
												<p className="mb-2 fs-16 font-w600">MK</p>
												<h4 className="mb-0 fs-18 font-w700">{data[0] && data[0].tryout_name}</h4>
											</div>
										</div>
										<div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
											{/* <i className="fas fa-envelope scale5 me-4 mt-2"></i> */}
											<div>
												<p className="mb-2 fs-16 font-w600">Durasi</p>
												<h4 className="mb-0 fs-18 font-w700">{data[0] && data[0].exam_duration_minutes} menit</h4>
											</div>
										</div>
										<div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
											{/* <i className="fas fa-envelope scale5 me-4 mt-2"></i> */}
											<div>
												<p className="mb-2 fs-16 font-w600">Status</p>
												<h4 className="mb-0 fs-18 font-w700">{data[0] && data[0].status === '1' ? 'Selesai' : 'Belum Selesai'}</h4>
											</div>
										</div>
										<div className="d-flex mb-3">
											{data[0] && data[0].is_expired > 0 ? (
												<Link to={"#"} className="btn btn-primary"><FontAwesomeIcon icon={faLock} /> Tidak Tersedia</Link>												
											):(
												<Link to={"#"} className="btn btn-primary" onClick={()=>mulai()}><i className="fas fa-check me-3"></i>Mulai</Link>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="card-body py-3 transaction-details d-flex flex-wrap justify-content-between align-items-center">
								<div className="user-bx-2 me-3 mb-3">
									<img src={profile} className="rounded" alt="" />
									<div>
										<h3 className="fs-20 font-w700">{data[0] && data[0].first_name}</h3>
										<span className="font-w400">user</span>
									</div>
								</div>
								<div className="me-3 mb-3">
									<p className="mb-2">Jenis Test/TO</p>
									<h4 className="mb-0">{data[0] && data[0].type_name}</h4>
								</div>
								<div className="me-3 mb-3">
									<p className="mb-2">Tanggal Mulai</p>
									<h4 className="mb-0">{data[0] && data[0].start_date}</h4>
								</div>
								<div className="me-3 mb-3">
									<p className="mb-2">Tanggal Selesai</p>
									<h4 className="mb-0">{data[0] && data[0].end_date}</h4>
								</div>
							</div>
						</>
						)}

						{data.length == 0 && (
							<div className="d-xl-flex d-block align-items-start description-bx p-3">
								<p>Saudara belum terdaftar di Ujian ini</p>
							</div>


						)}
					</div>
				</div>
				<div className="col-xl-12">
					<div className="card py-4">	
						<div className="card-body">
							<div className="d-xl-flex d-block align-items-start description-bx">
								<div>
									<h4 className="fs-20 font-w700">Deskripsi</h4>
									<span className="fs-12">{data[0] && data[0].description}</span>
									<p className="description mt-4"></p>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</>
	)
}
export default TryoutDetail;