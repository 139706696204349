import React from "react";

import { Link } from "react-router-dom";

const Header = ({ onNote }) => {
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("_");
  var filterName = path.length >= 3 ? path.filter((n, i) => i > 1) : name;
  var finalName = filterName;
  console.log('finalName',finalName);
  if (Number.isInteger(parseInt(finalName[finalName.length-1]))) {
    console.log('nomor');
    finalName = finalName[0];
  } else {
    console.log('huruf');

  }
  console.log(finalName);
  return ( 
    <div className="header border-bottom">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
                >
                  {finalName == 'tryout' ? 'Ujian' : finalName}
              </div>
            </div>            
            <ul className="navbar-nav header-right">
              <li className="nav-item invoices-btn">
                <Link to={"/"} className="btn btn-primary ms-5"><i className="far fa-file-alt fs-20 me-2"></i>Ujian Saya</Link>
              </li>				
            </ul>
          </div>  
        </nav>
      </div>
    </div>
  );
};

export default Header;
