 import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer fixed-bottom" style={{zIndex:0}}>
      <div className="copyright">
        <p>
          {/* Copyright © 
          {` ${d.getFullYear()}`} */}
        </p>
      </div>
    </div>
  );
};

export default Footer;
